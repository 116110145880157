<template lang="html">
  <section class="mx-auto my-16 md:my-24 px-4 lg:px-0 w-full lg:w-1024">
    <div class="inner">
      <p class="eula">
        <b>Perjanjian Lisensi Pengguna Akhir</b> <br><br>
        <b>Tanggal berlaku: 6 Mei 2022</b> <br><br>
        <b>Definisi</b> <br><br>
        Harap baca Perjanjian Lisensi Pengguna Akhir (PLPA) ini dengan seksama sebelum menekan tombol "Saya Setuju", mengunduh atau menggunakan aplikasi Nexgen English Online Co. ("Nexgen") (Myneo, Aplikasi neo Study, neo Study Online, neo Classroom dan neo LIVE), courseware Nexgen, situs web Nexgen, atau layanan Nexgen (“Aplikasi”). Nexgen adalah pemilik Aplikasi dan merupakan perusahaan California yang terdaftar. PLPA ini merupakan perjanjian hukum antara individu atau entitas tunggal (Anda, milik Anda) dan Nexgen dan mengatur penggunaan Anda atas Aplikasi, Courseware, dan Layanan yang disediakan untuk Anda oleh Nexgen. Dengan menekan tombol "Saya Setuju", mengunduh atau menggunakan Aplikasi, Anda setuju untuk terikat dengan syarat dan ketentuan PLPA ini. Penggunaan istilah organisasi dalam perjanjian ini mengacu pada pihak ketiga sekolah, mitra bisnis, reseller atau individu atau perusahaan lain yang menyelenggarakan kursus atau pelatihan Bahasa Inggris berdasarkan Aplikasidan materi Nexgen. Jika Anda tidak setuju dengan ketentuan PLPA ini, jangan menekan tombol "Saya Setuju" dan jangan mengunduh atau menggunakan Aplikasi. 

        <br><br><b>Lisensi</b><br><br>
        Aplikasi ini berlisensi dan tidak dijual kepada Anda oleh Nexgen. Nexgen memberi Anda lisensi non-eksklusif yang dapat dibatalkan, tidak dapat dipindahtangankan, dan terbatas untuk diunduh, dipasang, dan digunakan, semata-mata untuk tujuan yang ditetapkan dalam ketentuan PLPA ini. Aplikasi ini hanya tersedia untuk penggunaan individu saja dan tidak dapat dipindahtangankan. Setelah pendaftaran, layanan akan menjalankan prosedur konfirmasi yang akan diakhiri dengan "Konfirmasi Akun" yang akan menyatakan bahwa Anda adalah pemilik akun Anda setelah Anda melakukan "Konfirmasi". Jika Anda mengetahui adanya penggunaan yang tidak sah atas akun Anda, segera beritahukan kepada Nexgen. Nexgen tidak akan bertanggung jawab atas kehilangan atau kerusakan yang timbul dari kegagalan Anda untuk mematuhi ketentuan ini. hak Anda untuk menggunakan Aplikasi ditentukan dalam PLPA ini, dan Nexgen memiliki semua hak yang tidak dinyatakan secara tegas diberikan kepada Anda dalam PLPA ini.  Anda dilarang menyalin, mereproduksi, mendistribusikan, atau mengeksploitasi konten, kode, data, materi, atau desain apa pun, termasuk namun tidak terbatas pada hak cipta, hak paten, hak basis data, atau kekayaan intelektual dan hak kepemilikan apa pun di dalamnya. Penggunaan aplikasi, courseware, atau layanan Nexgen tidak memberi Anda kepemilikan atas konten, kode, data, atau materi Nexgen apa pun. Setiap distribusi, penerbitan, atau eksploitasi Aplikasi Nexgen sangat dilarang kecuali Anda telah menerima izin tertulis sebelumnya dari Nexgen. Tidak ada dalam PLPA ini yang merupakan pengesampingan hak Nexgen berdasarkan undang-undang Hak Cipta Amerika Serikat dan Internasional atau undang-undang federal atau negara bagian lainnya, undang-undang hak cipta, atau undang-undang lain yang berlaku di negara lain.  

        <br><br><b>Layanan Pihak Ketiga</b><br><br>
        Aplikasi dapat menampilkan, menyertakan, atau menyediakan konten pihak ketiga (termasuk data, informasi, aplikasi, dan layanan produk lainnya) atau menyediakan tautan ke situs web atau layanan pihak ketiga (“Layanan Pihak Ketiga”). Anda mengakui dan setuju bahwa Nexgen tidak bertanggung jawab atas Layanan Pihak Ketiga apa pun, termasuk keakuratan, kelengkapan, ketepatan waktu, validitas, kepatuhan hak cipta, legalitas, kesopanan, kualitas, atau aspek lainnya. Nexgen tidak menganggap dan tidak akan memiliki kewajiban atau tanggung jawab apa pun kepada Anda atau orang atau entitas lain mana pun atas Layanan Pihak Ketiga mana pun. Layanan Pihak Ketiga dan tautannya disediakan semata-mata untuk kenyamanan Anda dan akses Anda; gunakan sepenuhnya atas risiko Anda sendiri dan tunduk pada syarat dan ketentuan pihak ketiga tersebut. 

        <br><br><b>Persyaratan Layanan</b><br><br>
        1. Dengan menggunakan Aplikasi, Anda belajar untuk berhasil menyelesaikan tingkat Kerangka Acuan Umum Eropa untuk Bahasa (“CEFR”) dan dapat memperoleh Sertifikat Bahasa Inggris yang berkorelasi CEFR.<br>
        2. Aplikasi mengharuskan Anda untuk memenuhi Poin Studi dan Pelatihan. Poin studi diperoleh dengan belajar dengan Aplikasi. Persyaratan Poin Studi tergantung pada tingkat Sertifikat dan dapat ditemukan di Aplikasi. Nexgen menyarankan Anda melakukan satu sesi pelatihan online dengan Aplikasi setiap dua minggu, di mana setiap sesi akan memberi Anda Poin Pelatihan. Persyaratan Poin Pelatihan dapat ditemukan di Aplikasi dan Pertanyaan yang Sering Diajukan (“FAQ”) Nexgen.<br>
        3. Setelah Anda memenuhi persyaratan Poin Studi dan Pelatihan, Nexgen akan meminta Anda untuk mengikuti Tes Sertifikat level di Aplikasi. Pada titik ini, Anda dapat memilih untuk mengikuti Ujian Sertifikat atau mempelajari pelajaran tinjauan sebagai persiapan sebelum Ujian Sertifikat. <br>
        4. Jika Anda gagal dalam Tes Sertifikat, Nexgen akan meminta Anda untuk mempelajari pelajaran ulasan di Aplikasi. Tes Sertifikat level akan terbuka lagi setelah Anda menyelesaikan pelajaran ulasan yang diperlukan. Nexgen akan mengizinkan Tes Sertifikat level dibuka maksimal tiga (3) kali. <br>
        5. Setelah Anda lulus Tes Sertifikasi tingkat Sertifikat, Nexgen akan menyediakan sertifikat yang Anda peroleh setelah Anda membayar biaya tingkat tersebut. <br>
        6. Nexgen akan membuat sertifikat Anda tersedia melalui Aplikasi. Anda dapat memilih untuk mencetak sertifikat untuk dipresentasikan ke sekolah, universitas, atau perusahaan Anda. <br>
        7. Sertifikat berkorelasi CEFR Nexgen dikeluarkan oleh perusahaan pendidikan DynEd International, Inc., yang berbasis di San Jose, California. <br>
        8. Nexgen menyediakan Session Simulator, alat eksklusif yang dapat Anda gunakan untuk melakukan simulasi sesi pelatihan online di Aplikasi. Menjalankan langkah ini adalah wajib untuk menentukan apakah koneksi Internet Anda memadai untuk terhubung dan berinteraksi dalam sesi pelatihan online. Langkah ini harus dijalankan di lingkungan dimana tempat sesi yang dipesan akan berlangsung. Jika koneksi internet tidak memberikan bandwidth atau stabilitas yang cukup, Nexgen menyarankan Anda beralih ke koneksi Internet yang lebih cepat atau lebih stabil. Nexgen tidak bertanggung jawab atas gangguan koneksi apa pun saat mencoba terhubung atau selama sesi yang sedang berlangsung. <br>
        9. Jika pelatih terlatih kami tidak muncul untuk sesi pelatihan online yang dijadwalkan atau terlambat lima menit atau lebih, Nexgen akan fasilitasi dan menyediakan sesi pelatihan pengganti. <br>

        <br><b>Jaminan Sertifikat</b><br><br>
        1. Nexgen juga menawarkan jaminan bahwa dalam beberapa bulan tertentu Anda akan berhasil lulus Tes Sertifikat tingkat yang diperoleh dan mendapatkan sertifikat terkait CEFR. Lihat FAQ Nexgen untuk jaminan waktu belajar. <br>
        2. Agar memenuhi syarat untuk jaminan, Anda harus mendapatkan jumlah Poin Studi yang telah ditentukan sebelumnya setiap minggu dan menyelesaikan jumlah sesi Pelatihan minimum per level. Lihat FAQ Nexgen untuk Poin Studi dan persyaratan sesi Pelatihan. <br>
        3. Jika Anda gagal dalam Tes Sertifikasi pertama level Anda pada atau sebelum hari terakhir periode jaminan untuk level studi Anda, Nexgen akan mengizinkan Anda untuk memesan sesi pelatihan online lain sehingga Anda dapat lebih siap untuk mencoba kedua di Sertifikasi level Tes. <br>
        4. Jaminan Nexgen hanya untuk memberikan sesi pelatihan online gratis untuk kegagalan pertama kali Tes Sertifikat level. Kegagalan Uji Sertifikat berikutnya, pada tingkat yang sama, tidak menghasilkan sesi pelatihan online tambahan. Jika Anda telah menyelesaikan semua persyaratan untuk jaminan dan gagal dalam tes online, Nexgen akan menghubungi Anda untuk menerima wawancara online pribadi dengan ahli pengujian. <br>
        5. Jika Tes Sertifikat Anda dibuka setelah hari terakhir periode jaminan untuk tingkat studi Anda dan Anda gagal dalam Tes Sertifikat pada percobaan pertama Anda, Nexgen tidak akan memfasilitasi sesi pelatihan online tambahan.  <br>
        6. CATATAN KHUSUS: Jaminan ini hanya berlaku untuk program belajar mandiri yang menggunakan pelatih Nexgen dan sistem pemesanan yang disedikan oleh Nexgen. Hubungi Organisasi Resmi Nexgen Anda (selanjutnya disebut NAO) untuk mengetahui penawaran dan program mereka. <br>

        <br><b>Kebijakan pribadi</b><br><br>
        Kebijakan privasi Nexgen mencakup pengumpulan dan penggunaan informasi pribadi yang dapat dikumpulkan oleh Nexgen kapan pun Anda berinteraksi dengan Nexgen, seperti saat Anda mengunjungi situs web kami, saat Anda menggunakan produk dan layanan Nexgen, atau saat Anda menghubungi perwakilan dukungan kami. Untuk memastikan seluruh aspek Aplikasi berfungsi, diperlukan berbagai izin perangkat keras. Hal ini termasuk perizinan untuk membaca dan menulis ke kartu SD, mengakses jaringan, mikrofon, atau perekaman suara. Jika pengguna tidak memberikan izin atas akes tersebut, pengguna dapat menonaktifkan “Izin Membaca dan Menulis ke kartu SD”, “Izin Akses Jaringan”, dan “Izin Penggunaan Mikrofon;” namun, hal ini akan menghambat kerja Aplikasi. <br>

        1. <b>Mengapa Nexgen mengumpulkan informasi pribadi?</b> Nexgen mengumpulkan informasi pribadi untuk membantu memberikan tingkat layanan dan dukungan yang unggul. Selain itu, informasi pribadi Anda membantu Anda tetap mendapat informasi tentang pengumuman produk, pembaruan, dan penawaran khusus. Jika Anda berlangganan milis elektronik Nexgen, Nexgen dapat mengirimkan informasi terbaru tentang produk dan layanan kepada Anda secara berkala. Anda dapat memilih untuk berhenti berlangganan kapan saja.<br>
        2. <b>Informasi apa yang dikumpulkan Nexgen?</b> Informasi pribadi Anda membantu memberikan layanan yang lebih baik kepada Anda dalam beberapa cara. Pada saat-saat tersebut, Nexgen dapat mengumpulkan informasi pribadi yang relevan dengan situasi tersebut, seperti nama, nomor telepon, alamat email, informasi tentang produk neo yang Anda miliki, seperti tanggal pembelian, dan informasi yang berkaitan dengan masalah dukungan atau layanan. <br>
        3. <b>Apakah Nexgen mengungkapkan informasi Anda?</b> Nexgen menjaga privasi Anda dengan sangat serius. Saat Anda menggunakan produk atau layanan neo, Anda mempercayai Nexgen dengan informasi Anda. Nexgen tidak membagikan informasi pribadi dengan perusahaan, organisasi, atau individu di luar Nexgen (dan perusahaan afiliasi) kecuali dengan persetujuan Anda atau jika diwajibkan oleh hukum.<br>
        4. <b>Bagaimana cara Nexgen melindungi informasi pribadi Anda?</b> Nexgen mengambil semua tindakan pencegahan yang wajar, termasuk tindakan administratif, teknis, dan fisik, untuk melindungi informasi pribadi Anda dari kehilangan, pencurian, penyalahgunaan, serta akses, pengungkapan, perubahan, dan penghancuran yang tidak sah.<br>
        5. <b>Kepatuhan privasi dan data khusus kawasan?</b> Produk dan layanan Nexgen sepenuhnya mematuhi, pada saat Tanggal Efektif, dengan peraturan Privasi Data berikut:<br>

        <div style="margin:0 0 0 20px;">
          a. GDPR, Peraturan Perlindungan Data Umum (UE) <br>
          b. FERPA, Hak Pendidikan Keluarga dan Undang-Undang Privasi (AS) <br>
          c. COPPA, Undang-Undang Perlindungan Privasi Daring Anak-anak (AS) <br>
          d. DSL, Hukum Keamanan Data (Cina) <br>
          e. PDPA, Undang-Undang Perlindungan Data Pribadi (Singapura) <br>
          f. APPI, Undang-Undang tentang Perlindungan Informasi (Jepang) <br>
          g. PIPEDA, Undang-Undang Perlindungan Informasi Pribadi dan Dokumen Elektronik (Kanada) <br>
          h. CCPA, Undang-Undang Privasi Konsumen California (AS) <br>
          i. CalOPPA, California Undang-Undang Perlindungan Privasi Online (AS) <br>
        </div>

        6. <b>Penghapusan Informasi Identifikasi Pribadi</b> Nexgen mempertahankan Informasi Identifikasi Pribadi minimum (“IIP”) untuk memungkinkan akses terkontrol ke layanan kami dan memungkinkan validasi sertifikat yang diperoleh. Namun, jika Anda ingin OPT-OUT dan IIP Anda dihapus dari basis data kami, Anda dapat mengajukan permohonan ke legal@nexgenenglishonline.co untuk meminta penghapusan IIP Anda dengan menyatakan, “Saya telah membaca, memahami, dan menyetujui dengan Penghapusan Klausul Informasi Identifikasi Pribadi pada Perjanjian Lisensi Pengguna Akhir.” Penghapusan IIP Anda akan terjadi sesegera mungkin setelah verifikasi bahwa permintaan tersebut sah. Mungkin ada beberapa kasus dimana Nexgen diharuskan menyimpan IIP Anda untuk mematuhi kewajiban hukum yang berlaku, atau untuk menyelesaikan perselisihan. Ketika permintaan untuk Penghapusan Informasi Identifikasi Pribadi telah dipenuhi sebelum Anda menyelesaikan kursus Anda, Anda tidak mungkin melanjutkan studi. Selanjutnya, Nexgen tidak akan dapat melakukan verifikasi atau menerbitkan ulang atas sertifikat neo yang diperoleh jika IIP Anda telah dihapus. Jika Anda ingin data Anda dihapus dan Anda merupakan siswa aktif yang terdaftar dalam NAO, maka NAO harus dihubungi dan permintaan penghapusan ditujukan kepada mereka. Namun, jika Anda tidak lagi terdaftar dengan NAO, Anda mendapatkan hak untuk secara langsung meminta Nexgen untuk menghapus data IIP Anda, sementara NAO kehilangan hak untuk mengakses ke data Nexgen Anda. Namun, setiap NAO yang anda daftarkan memiliki salinan yang sah secara terpisah dari data IIP yang disimpan untuk tujuan administrative. Anda perlu menghubuni NAO untuk menghapus data tersebut.<br>

        <br> <b>Masalah Privasi</b> <br><br>
        Jika Anda memiliki pertanyaan tentang Kebijakan Privasi Pelanggan atau metodologi pemrosesan data kami, silakan kirim email ke legal@nexgenenglishonline.co <br>

        <br> <b>Pendaftaran dan Persyaratan Siswa Mandiri </b> <br><br>
        1. Anda harus mendaftar dan membayar biaya tertentu untuk mendapatkan akses penuh ke Aplikasi dan fungsi Nexgen. Anda akan diminta untuk memberikan informasi dan data pribadi Anda (“Informasi Pribadi”) sebagai bagian dari proses.<br>
        2. Sebelum pembayaran, Anda akan diminta untuk membeli Sertifikat di level berikutnya, yang menentukan biaya yang akan dikenakan kepada Anda. NAO mungkin menawarkan paket pembayaran berbeda yang berlaku di wilayah Anda. <br>
        3. Setelah Anda menyelesaikan proses pendaftaran dan pembayaran, Nexgen akan meminta Anda untuk membuat satu login (“Neo Universal Login”) yang akan digunakan untuk mengakses Aplikasi. <br>
        4. Untuk mulai belajar, Anda harus mengunduh Aplikasi Neo Study dan Aplikasi myneo ke perangkat yang memenuhi persyaratan yang ada pada https://nexgenenglishonline.co/support <br>
        5. Dengan mendapatkan Akun melalui proses pendaftaran dan pembayaran, Anda juga mendapatkan token yang diperlukan untuk pelatihan, tergantung pada pilihan paket Anda, dan Anda perlu menjadwalkan sesi pelatihan online dengan pelatih terlatih kami di Aplikasi Myneo. <br>

        <div style="margin:0 0 0 20px;">
          5.1 Lima sesi pelatihan diberikan oleh Nexgen atas setiap tingkat sertifikasi untuk semua individu yang telah membayar akses Nexgen Live dan sesi ini diperlukan untuk memenuhi persyaratan Poin Pelatihan. Jika mereka yang memiliki Nexgen Live tidak hadir selama sesi yang dipesan dimana menyebabkan Anda tidak mendapatkan poin pelatihan, Nexgen tidak akan bertanggung jawab atas kegagalan Anda untuk mematuhi ketentuan ini. Dalam hal ini, Anda harus membeli sesi tambahan untuk memenuhi persyaratan poin pelatihan. <br>
          5.2 Nexgen menawarkan sesi tambahan untuk pembelian. Jika Anda membeli lebih banyak sesi selama studi Anda, sisa sesi yang tidak digunakan setelah menyelesaikan tingkat sertifikasi Anda akan tetap berada di akun Anda dan dapat digunakan sesuai kebijaksanaan Anda untuk sertifikat di masa mendatang, namun, sesi yang tidak digunakan tidak dapat dikembalikan. <br>
          5.3 Nexgen tidak membatasi frekuensi pemesanan sesi pelatihan online dengan pelatih terlatih Nexgen di Aplikasi. Tetapi, untuk pembelajaran yang efektif, Nexgen sangat menyarankan Anda memesan sesi pelatihan setidaknya sekali setiap dua minggu seperti yang disarankan dalam Persyaratan Layanan Nexgen di atas. <br>
          5.4 Browser yang Didukung dapat ditemukan di sini https://nexgenenglishonline.co/support <br>
        </div>

        6. Persyaratan yang tercantum diatas hanya berlaku bagi siswa yang mengikuti kursus belajar mandiri. Siswa yang terdaftar dengan NAO yang menggunakan produk Nexgen harus megikuti persyaratan organisasi tertentu, yang kemungkinan berbeda dengan yang diatas. <br>
      </p>
      <p class="eula">
        <br><b>Penyesuaian Login dengan Peraturan Privasi </b><br><br>
        neo Universal Login dan identifikasi pengguna diformat sebagai email, hal ini dilakukan agar login mudah diingat. Untuk semua program belajar mandiri, Nexgen harus memverifikasi semua alamat email dan nomor ponsel melalui email dan SMS. Login ini secara inheren unik secara global. <br>
        CATATAN KHUSUS: Organisasi yang harus mematuhi peraturan dengan FERPA, COPPA, dan GDPR harap berhati-hati dan mengikuti peraturan data pemerintah setempat dan gunakan login berformat email untuk semua siswa dan staf Anda di sistem Nexgen. Peringatan - format string login yang Anda pilih mungkin sudah ada di sistem kami secara global, jika ya, Anda harus memilih string login berformat lain yang unik. Untuk saran tentang cara menyesuaikan string login Anda, silakan hubungi perwakilan resmi Nexgen setempat atau email kami di support@nexgenenglishonline.co. <br>

        <br><b>Penghentian </b><br><br>
        PLPA ini akan tetap berlaku hingga diakhiri oleh Anda atau Nexgen. Nexgen dapat, atas kebijakannya sendiri, kapan saja dan untuk alasan apa pun atau tanpa alasan apa pun, menangguhkan atau mengakhiri PLPA ini dengan atau tanpa pemberitahuan sebelumnya. PLPA ini akan berakhir langsung, tanpa pemberitahuan sebelumnya dari Nexgen jika Anda gagal mematuhi ketentuan apa pun dari PLPA ini. Anda juga dapat mengakhiri PLPA ini dengan menghapus Aplikasi dan semua salinannya dari perangkat seluler atau komputer Anda. Setelah pengakhiran PLPA ini, Anda harus menghentikan semua penggunaan Aplikasi dan menghapus semua salinan Aplikasi dari perangkat seluler atau komputer Anda. Pengakhiran PLPA ini tidak akan membatasi hak atau upaya hukum Nexgen apa pun secara hukum atau keadilan jika Anda melakukan pelanggaran terhadap kewajiban Anda berdasarkan PLPA ini.  

        <br><br><b>Amandemen Perjanjian ini </b><br><br>
        Nexgen berhak, atas kebijakannya sendiri, untuk mengubah atau mengganti PLPA ini kapan saja. Jika revisi bersifat perubahan material, Nexgen akan memberikan pemberitahuan setidaknya 30 hari sebelum persyaratan baru berlaku. Apa yang merupakan perubahan material akan ditentukan atas kebijakan Nexgen sendiri. Dengan terus mengakses atau menggunakan Aplikasi Nexgen setelah revisi efektif berlaku, Anda setuju untuk terikat dengan persyaratan yang telah direvisi. Jika Anda tidak menyetujui persyaratan baru, Anda tidak lagi berwenang untuk menggunakan Aplikasi dan Pemutusan (diatas) berlaku.  

        <br><br><b>Tidak Ada Jaminan </b><br><br>
        Anda secara tegas mengakui dan menyetujui bahwa penggunaan Aplikasi adalah risiko Anda sendiri. Sejauh diizinkan oleh hukum yang berlaku, Aplikasi dan layanan apa pun yang dilakukan atau disediakan oleh Aplikasi disediakan "sebagaimana adanya" dan "sebagaimana tersedia", dengan semua kesalahan dan tanpa jaminan dalam bentuk apa pun, dan Nexgen dengan ini menyangkal semua jaminan dan ketentuan mengenai Aplikasi dan layanan apa pun, baik tersurat, tersirat, atau menurut undang-undang, termasuk, namun tidak terbatas pada, jaminan tersirat dan/atau kondisi yang dapat diperjualbelikan, kualitas yang memuaskan, kesesuaian untuk tujuan tertentu, akurasi, penggunaan, dan tidak melanggar hak pihak ketiga. Tidak ada informasi atau saran lisan atau tertulis yang diberikan oleh Nexgen atau perwakilan resminya yang dapat menimbulkan jaminan. Jika Aplikasi atau layanan terbukti rusak, Anda menanggung seluruh biaya dari semua servis, perbaikan, atau koreksi yang diperlukan. Beberapa yurisdiksi tidak mengizinkan pengecualian atas jaminan tersirat atau pembatasan hak konsumen menurut undang-undang yang berlaku, sehingga pengecualian dan pembatasan di atas mungkin tidak berlaku untuk Anda.  

        <br><br><b>Batasan Tanggung Jawab</b><br><br>
        Sejauh tidak dilarang oleh hukum, dalam keadaan apa pun Nexgen tidak bertanggung jawab atas cedera pribadi atau kerusakan insidental, khusus, tidak langsung, atau konsekuensial apa pun, termasuk, tanpa batasan, ganti rugi atas hilangnya keuntungan, kehilangan data, gangguan bisnis, atau kerusakan atau kerugian komersial lainnya, yang timbul dari atau terkait dengan penggunaan atau ketidakmampuan Anda untuk menggunakan Aplikasi, apa pun penyebabnya, terlepas dari teori pertanggungjawaban (kontrak, kesalahan, atau lainnya) dan bahkan jika Nexgen telah diberitahu tentang kemungkinan dari kerusakan tersebut. Beberapa yurisdiksi tidak mengizinkan pembatasan tanggung jawab atas cedera pribadi, atau kerusakan insidental atau konsekuensial, sehingga pembatasan ini mungkin tidak berlaku untuk Anda. Dalam keadaan apa pun, tanggung jawab total Nexgen kepada Anda atas semua kerusakan (selain yang mungkin diwajibkan oleh hukum yang berlaku dalam kasus yang melibatkan cedera pribadi) tidak boleh melebihi jumlah yang sama dengan harga pembelian lisensi Aplikasi. Batasan di atas akan berlaku bahkan jika pemulihan yang disebutkan di atas gagal dari tujuan dasarnya.  

        <br><br><b>Peraturan Pemerintah </b><br><br>
        Hukum California, Amerika Serikat, tidak termasuk atas pertentangan aturan hukumnya, akan mengatur PLPA ini dan penggunaan Aplikasi oleh Anda. Penggunaan Aplikasi oleh Anda juga dapat tunduk pada hukum lokal, negara bagian, nasional, atau internasional lainnya.  

        <br><br><b>Seluruh Perjanjian </b><br><br>
        PLPA merupakan keseluruhan perjanjian antara Anda dan Nexgen mengenai penggunaan Aplikasi oleh Anda dan menggantikan semua perjanjian tertulis atau lisan sebelumnya dan saat ini antara Anda dan Nexgen.  

        <br><br><b>Kontak Informasi</b><br><br>
        Jika Anda memiliki pertanyaan tentang Perjanjian ini, silakan hubungi kami di legal@nexgenenglishonline.co 
      </p>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.eula {
  text-align: justify;
}
b {
  font-weight: bold;
}
</style>
